import React from "react";
import { useContainerContext } from "../store/ContainerContext";

function Counter(){
  const context = useContainerContext();
  const { count } = context.state;
  const { increaseDispatch, decreaseDispatch } = context.actions;
  return (
    <div>
      <h4>Count: {count}</h4>
      <button onClick={() => increaseDispatch()}>+</button>
      <button onClick={() => decreaseDispatch()}>-</button>
    </div>
  );
};

export default Counter